// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$helpmefix-calculator-primary: mat.define-palette(mat.$indigo-palette);
$helpmefix-calculator-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$helpmefix-calculator-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$helpmefix-calculator-theme: mat.define-light-theme((
  color: (
    primary: $helpmefix-calculator-primary,
    accent: $helpmefix-calculator-accent,
    warn: $helpmefix-calculator-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($helpmefix-calculator-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, sans-serif; }

.content-wrapper {
  font: 14px/16px 'Roboto', sans-serif;
  min-height: 100%;
  font-weight: 400;
  color: #2B2A29;
  width: 920px;
  margin: 0 auto;

  @media screen and (max-width: 992px) {
    width: 96%;
  }

  .content {
    padding: 72px 95px;
    border: 1px solid #e5e5e5;
    border-radius: 6px;

    @media screen and (max-width: 992px) {
      padding: 30px 48px;
    }

    @media screen and (max-width: 600px) {
      padding: 16px 32px;
    }

    .line-flex {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 600px) {
        display: block;
      }

      .form-field-section {
        width: 100%;

        label {
          display: block;
          font-weight: 700;
          margin-bottom: 8px;
        }

        .mat-form-field {
          width: 100%;
        }
      }

      .form-first-item {
        margin-right: 30px;
      }
    }

    .m-top {
      margin-top: 8px;
    }

    .info-section {
      background-color: rgba(176, 203, 31, 0.1);
      padding: 16px 110px;
      border-radius: 6px;
      text-align: center;
      @media screen and (max-width: 992px) {
        padding: 16px 30px;
      }

      p {
        margin: 0;

        span {
          color: #93A823;
          font-weight: 600;
        }
      }
    }

    .mb-8 {
      margin-bottom: 8px;
    }

    .info-section-first {
      margin-top: 24px;
    }

    .bottom-text {
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      margin: 40px 0 0;
    }
  }
}

::ng-deep .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  padding: 0 16px !important;
}

::ng-deep .mat-form-field-subscript-wrapper {
  margin-top: 4px !important;
}

.mat-tab-label {
  opacity: 1 !important;
  height: 36px !important;
  padding: 0 24px !important;
  @media screen and (max-width: 600px) {
    padding: 0 12px !important;
  }

  .mat-tab-label-content {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #2b2a29;
    @media screen and (max-width: 600px) {
      font-size: 14px;
    }
    @media screen and (max-width: 400px) {
      font-size: 13px;
    }
  }
}

.mat-tab-label.mat-tab-label-active {
  .mat-tab-label-content {
    color: #ef7f1a;
  }
}

.mat-tab-nav-bar, .mat-tab-header {
  border: none;
}

.mat-tab-group.mat-primary .mat-ink-bar {
  background-color: #ef7f1a;
}

.mat-tab-group {
  margin-top: 32px;
}

.mat-tab-header-pagination {
  display: none !important;
}

.mat-form-field-infix {
  border-top: 0.5em solid transparent !important;
}
